import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import Profile from '.';

const TeamQC = () => {
  return (
     <Profile image={'portrait.jpg'}/>
   );
};
export default TeamQC;
